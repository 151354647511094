import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import '../styles/404.sass'

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Introuvable" />
        <center>
            <h1 style={{ fontSize: '800%' }}>404 NOT FOUND</h1>
            <p style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
                La page que vous cherchez n'existe pas...🤔
                <br />
                <br />
                <br />
                <br />
            </p>
        </center>
    </Layout>
)

export default NotFoundPage
